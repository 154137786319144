
//- Slider
$(document).ready(function(){
  $('.main-floor__img').slick({
  	autoplay: true,
  	autoplaySpeed:5000,
	slidesToShow: 1,
	slidesToScroll: 1,
	infinite: true,
	arrows: false,
	fade: true,
	dots: false
  });
});



//- Menu close/open
$(document).ready(function () {
	$(".menu-button").click(function () {
		$(this).toggleClass("menu-button--to-open"),
		$(this).toggleClass("menu-button--to-close"),
		$(this).hasClass("menu-button--to-close") ? $(".menu").addClass("menu--visible") : $(".menu").removeClass("menu--visible")
	})
});

//- При изменении ширины окна браузера на ширине 1200px сворачивается меню, если оно было развернуто.
//- срабатывает событие клика на кнопку, чтобы и меню, и кнопка приняли необходимый вид для широких экранов
$(window).resize(function(){
	if($(window).width()>=1200){
		if($('.menu').hasClass('menu--visible')){
			$('.menu-button').click();
		}
	}
});

//- Меняем цвет иконок соцсетей при скроле
$(document).ready(function () {
    $(window).scroll(function() {
        if ($(window).scrollTop() > $(".main-floor").height()) {
            $(".sidebar-social").addClass("sidebar-social__light");
        } else {
            $(".sidebar-social").removeClass("sidebar-social__light");
        }
    })
});

//- Меняем высоту меню при скроле
$(document).ready(function () {
    $(window).scroll(function() {
        if ($(window).scrollTop() > 200) {
            $(".navbar").addClass("navbar--small");
        } else {
            $(".navbar").removeClass("navbar--small");
        }
    })
});

//- Инициализация fancybox для плавного открытия фото

$(document).ready(function () {
	$('[data-fancybox="images"]').fancybox({
		// Options will go here
		buttons:["close"],
		hideScrollbar: false,
		animationEffect: "zoom",
		transitionEffect: "tube",
		animationDuration: 1000,
		clickContent: function(current, event) {
        	return current.type === "image" ? "next" : false;
    	},
    	clickOutside: "close",
		btnTpl: {
			arrowLeft:
	        	'<a data-fancybox-prev class="fancybox-button fancybox-button--arrow_left icon icon-arrow-left" title="{{PREV}}" href="javascript:;"></a>',
	        arrowRight:
	        	'<a data-fancybox-next class="fancybox-button fancybox-button--arrow_right icon icon-arrow-right" title="{{NEXT}}" href="javascript:;"></a>'
        }
	});
});



$(document).ready(function($) {

	$element = $(".fixed_block");
	$element.css("width", $element.outerWidth());
	$window = $(window);
	$height_el = $element.offset().top;

	$window.scroll(function() {
	
		if($window.scrollTop() > $height_el) {
		
			$element.addClass("fixed");

		} else {
		
			$element.removeClass("fixed");

		}

	});

});




//- Форма обратной связи
function doajax(activeform) {
	var t, a = $(activeform).serialize();
	var reason = $(activeform+" button").text();
	console.log(a);
	if (activeform=="#form-contacts")
		var o = "php/contactform.php";
	else 
		var o = "php/callbackform.php";
	$.ajax({
		type: "POST",
		url: o,
		data: a,
		success: function (msg) {
			// Удаляем все из контейнера, в который выводятся результаты (на случай повторной отправки)
			$(activeform + " .form-result").empty();
			if (msg=="success")
				t = "<div class='result_success'>Grazie! Ti contatteremo entro 24 ore</div>",
				$(activeform + " .form-result").append(t)
			else
				t = "<div class='result_error'>"+ msg +"</div>",
				// $(activeform + " .form-result").empty(),
				$(activeform + " .form-result").append(t)
		},
		beforeSend: function () {
			$(activeform).find(":input").attr("disabled", !0),
			$(activeform + " .ajax-loader").css("display", "block")
		},
		complete: function () {
			$(activeform).find(":input").attr("disabled", !1),
			activeform=="#form-callback__popup" && $(".form-callback__inner").hide(),
			$(activeform + " .ajax-loader").css("display", "none"),
			$(activeform + " .form-result").css("display", "block"),
			$(activeform)[0].reset()
		}
	});
}



$("#form-callback__renew").submit(function (event) {
	event.preventDefault();
    if ($("#form-callback__renew")[0].checkValidity() === false) {
        event.stopPropagation();
        $("#form-callback__renew").addClass('was-validated');
    } else {
    	doajax("#form-callback__renew");
    	$("#form-callback__renew").removeClass("was-validated");
    	$("#form-callback__renew")[0].reset();
        //do your ajax submition here
    }
    // $("#form-callback").addClass('was-validated');
});


$("#form-callback__contacts").submit(function (event) {
    event.preventDefault();
    if ($("#form-callback__contacts")[0].checkValidity() === false) {
        event.stopPropagation();
        $("#form-callback__contacts").addClass('was-validated');
    } else {
    	doajax("#form-callback__contacts");
    	$("#form-callback__contacts").removeClass("was-validated");
    	$("#form-callback__contacts")[0].reset();
        //do your ajax submition here
    }
    // $("#form-callback").addClass('was-validated');
});

$("#form-contacts").submit(function (event) {
    event.preventDefault();
    if ($("#form-contacts")[0].checkValidity() === false) {
        event.stopPropagation();
        $("#form-contacts").addClass('was-validated');
    } else {
    	doajax("#form-contacts");
    	$("#form-contacts").removeClass("was-validated");
    	$("#form-contacts")[0].reset();
        //do your ajax submition here
    }
    // $("#form-callback").addClass('was-validated');
});

$("#form-callback__popup").submit(function (event) {
    event.preventDefault();
    if ($("#form-callback__popup")[0].checkValidity() === false) {
        event.stopPropagation();
        $("#form-callback__popup").addClass('was-validated');
    } else {
    	doajax("#form-callback__popup");
    	$("#form-callback__popup").removeClass("was-validated");
    	$("#form-callback__popup")[0].reset();
        //do your ajax submition here
    }
    // $("#form-callback").addClass('was-validated');
});


// Модальное окно для заказа звонка
// Для вызова модального окна кнопкам следует добавить класс .btn-callback

$(document).ready(function () {
	$(".btn-callback").click(function (a) {
		a.preventDefault();
		$(".form-callback")[0].reset();
		if($(this).hasClass('tocontact')){
			$(".popup-price").addClass('hidden');
			$(".popup-contact").removeClass('hidden');
		} else {
			$(".popup-contact").addClass('hidden');
			$(".popup-price").removeClass('hidden');
		} 
		// if ($(this).hasClass('promo__btn')){ //Меняем заголовок модального окна, если это промоблок
		// 	$("#callback-popup h3").html("Richiesta offerta");	
		// } else {
		// 	$("#callback-popup h3").html("Richiesta contatto");	
		// }
		$(".form-callback__inner").css("display", "block"), $(".callback-popup__overlay").fadeIn(400, function () {
			$(".callback-popup__content").css("display", "block").animate({
				opacity: 1
			}, 200)
		})
	}), $(".callback-popup__btn-close, .callback-popup__overlay").click(function () {
		$(".form-callback__result").html(""), $(".form-callback__result").css("display", "none"), $("#form-callback").removeClass("was-validated"), $(".callback-popup__content").animate({
			opacity: 0
		}, 200, function () {
			$(this).css("display", "none"), $(".callback-popup__overlay").fadeOut(400)
		}),
		$("#form-callback__popup").removeClass("was-validated"),
    	$("#form-callback__popup")[0].reset()
	})
});

